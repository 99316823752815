import React, { Suspense, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import history from "./components/history";
import CustomerInformation from "./components/customerInformation/customerInformation";
import Thankyou from "./components/thankyou/thankyou";
import Download from "./components/BasicComponents/download";
import Maps from "./components/Maps/Maps";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Assests/falti-icons/flaticon.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ErrorBoundary from "./components/BasicComponents/ErrorBoundary";
import { Review } from "./components/Review/Review";
import { Loading } from "react-loading-dot";
import ScrollToTop from "./ScrollToTop";
import DealsProperty from "./components/Deals/DealsProperty";
import { credientials } from "./whiteLabelsCredientials";
import {
  HomeScreenDetail,
  agentDetails,
  setTemplateTheme,
} from "./components/BasicComponents/BasicFunctionScript";
import { whatsapp_click } from "./RoomphGTMTracking";
import Cancellationbooking from "./Cancellationbooking";
const Home = React.lazy(() => import("./components/home/home"));
const Results = React.lazy(() =>
  import("./components/propertyListing/results/results")
);
const Description = React.lazy(() =>
  import("./components/propertyDetails/description/description")
);
const Deals = React.lazy(() => import("./components/Deals/Deals"));
const ErrorPage = React.lazy(() => import("./ErrorPage"));

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    var url = window.location.hostname;
    var hostCredientials = credientials.find(
      (c) => c.hostname.toLowerCase() == url.toLowerCase()
    );

    if (hostCredientials) {
      HomeScreenDetail(hostCredientials).then((value) => {
        //console.log(value.Collection);
        setTemplateTheme(value.Collection, hostCredientials).then(() => {
          setLoading(false);
        });
      });
    }
  }, []);
  
  return (
    <div className="App position-relative">
      {agentDetails.whatsapp_contact ? (
        <div className="icon-bar">
          <a
            href={agentDetails.whatsapp_contact}
            target="_blank"
            className="whatsapp m-0 p-0"
            onClick={() => whatsapp_click()}
          >
            <img src="images/Asset3.svg" width={55} alt="" />
          </a>
        </div>
      ) : null}
      <ErrorBoundary>
        <Router history={history}>
          <ScrollToTop />
          <Suspense
            fallback={<Loading dots={4} background="#7F7F7F" size="1.3rem" />}
          >
            {loading ? (
              <Loading dots={4} background="#7F7F7F" size="1.3rem" />
            ) : (
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/destination&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights">
                  <Results />
                </Route>
                <Route path="/information">
                  <CustomerInformation />
                </Route>
                <Route path="/hotels&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights&hotel_id=:id&discount=:discount">
                  <Description />
                </Route>

                <Route path="/deals/:deal-:id">
                  <DealsProperty />
                </Route>
                <Route path="/cancellation-booking&accommodationid=:accommodationid&bookingid=:bookingid&pincode=:pincode&agent=:agent">
                  <Cancellationbooking />
                </Route>
                <Route
                  path="/map&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms"
                  component={Maps}
                />
                <Route path="/thankyou" component={Thankyou} />
                <Route path="/Deals" component={() => <Deals />} />
                <Route path="/Review" component={Review} />
                <Route path="/download" exact component={() => <Download />} />
                <Route path="*" component={() => <ErrorPage />} />
              </Switch>
            )}
          </Suspense>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
