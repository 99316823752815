export const credientials = [
  {
    hostname: "localhost",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID:6

    // agent: "pia",
    // userId: 10002,
    // password: "YGiDp9ex0022019",
    // promotionID: 5,

    // agent: "golootlo",
    // userId: 10029,
    // password: "KL98H6YGV2",
    // promotionID: 11,

    // agent: "Travel Agent Portal",
    // password: "KJH34H0D01",
    // userId: 10008,
    // promotionID: 4,
  },
  {
    hostname: "192.168.10.2",
    agent: "golootlo",
    userId: 10029,
    password: "KL98H6YGV2",
    promotionID: 11,
  },
  {
    hostname: "hbl-stgg.ascendant.travel",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  {
    hostname: "54.246.101.206",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  {
    hostname: "hbldt-stg.ascendant.travel",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  {
    hostname: "hbl.ascendant.travel",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  {
    hostname: "whitelabelstest-production.up.railway.app",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  //ascendants data     pia-stgg.ascendant.travel
  {
    hostname: "pia-stgg.ascendant.travel",
    agent: "pia",
    userId: 10002,
    password: "YGiDp9ex0022019",
    promotionID: 5,
  },
  {
    hostname: "pia.ascendant.travel",
    agent: "pia",
    userId: 10002,
    password: "YGiDp9ex0022019",
    promotionID: 5,
  },
  {
    hostname: "wl.ascendant.travel",
    agent: "pia",
    userId: 10002,
    password: "YGiDp9ex0022019",
    promotionID: 5,
  },
  {
    hostname: "pia-stg.ascendant.travel",
    agent: "pia",
    userId: 10002,
    password: "YGiDp9ex0022019",
    promotionID: 5,
  },
  {
    hostname: "hbl-stg.ascendant.travel",
    agent: "HBL",
    userId: 10013,
    password: "PLYGE8UY7P8KJ",
    promotionID: 6,
  },
  //
  {
    hostname: "faysalbank.ascendant.travel",
    agent: "Faysal Bank",
    userId: 10021,
    password: "BV78RT04KL",
    promotionID: 7,
  },
  {
    hostname: "faysalbank-stg.ascendant.travel",
    agent: "Faysal Bank",
    userId: 10021,
    password: "BV78RT04KL",
    promotionID: 7,
  },
  //
  {
    hostname: "habibmetro.ascendant.travel",
    agent: "Habib Metro",
    userId: 10024,
    password: "JKU7R4*E9U",
    promotionID: 9,
  },
  {
    hostname: "habibmetro-stg.ascendant.travel",
    agent: "Habib Metro",
    userId: 10024,
    password: "JKU7R4*E9U",
    promotionID: 9,
  },
  {
    hostname: "mcb.ascendant.travel",
    agent: "mcb",
    userId: 10023,
    password: "OIU7R4CB9G",
    promotionID: 8,
  },
  {
    hostname: "mcb-stg.ascendant.travel",
    agent: "mcb",
    userId: 10023,
    password: "OIU7R4CB9G",
    promotionID: 8,
  },
  {
    hostname: "golootlo-stg.ascendant.travel",
    agent: "golootlo",
    userId: 10029,
    password: "KL98H6YGV2",
    promotionID: 11,
  },
  {
    hostname: "golootlo-stgg.ascendant.travel",
    agent: "golootlo",
    userId: 10029,
    password: "KL98H6YGV2",
    promotionID: 11,
  },

  {
    hostname: "golootlo.ascendant.travel",
    agent: "golootlo",
    userId: 10029,
    password: "KL98H6YGV2",
    promotionID: 11,
  },
  {
    hostname: "tap.ascendant.travel",
    agent: "Travel Agent Portal",
    password: "KJH34H0D01",
    userId: 10008,
    promotionID: 4,
  },
  {
    hostname: "askari.ascendant.travel",
    agent: "Travel Agent Portal",
    password: "KJH34H0D01",
    userId: 10008,
    promotionID: 4,
  },
];
