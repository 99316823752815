import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import RoomphLogo from "../../Assests/logo.svg";
import Button from "../BasicComponents/Button";
import Logowhite from "../../Assests/logo-white.svg";
import Modal from "@mui/material/Modal";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { isMobile } from "react-device-detect";
import {
  logo_url,
  white_logo_url,
} from "../BasicComponents/BasicFunctionScript";
import { click_view_deals } from "../../RoomphGTMTracking";

export default function Topbar(props) {
  return (
    <section className={`${props.page == "home" ? "home-bar" : "orange-bar"}`}>
      <div className="container-2 py-1">
        <div className="display-flex w-100 j-between item-center">
          <div>
            {props.page !== "home" ? (
              <Link to="/" className="roomph-logo">
                <img className="roomphLogoH" src={white_logo_url} alt="logo" />
              </Link>
            ) : (
              <Link to="/" className="roomph-logo">
                <img className="roomphLogoH" src={logo_url} alt="logo" />
              </Link>
            )}
          </div>
          <div className="">
            <div>
              {props.page !== "home" ? (
                <Link
                  onClick={() => click_view_deals(props.page)}
                  to={{
                    pathname: "/Deals",
                    state: { content: "No deals yet !" },
                  }}
                >
                  <Button
                    childern="View Deals"
                    type="button"
                    buttonStyle="btn-secondary"
                  ></Button>
                </Link>
              ) : (
                <Link
                onClick={() => click_view_deals(props.page)}
                  to={{
                    pathname: "/Deals",
                    state: { content: "No deals yet !" },
                  }}
                >
                  <Button
                    childern="View Deals"
                    type="button"
                    buttonStyle="btn-primary"
                  ></Button>
                </Link>
              )}

              <Link className="d-lg-none" to="/download" target="_blank">
                <button className="d-lg-none buttonApp">Use App</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
