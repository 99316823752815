import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";
import swal from "sweetalert";
import history from ".././history";

//FaFacebookF
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import {
  about_us,
  agentDetails,
  agent_bw_userId,
  logo_url,
  termsAndConditions,
  white_logo_url,
} from "../BasicComponents/BasicFunctionScript";
import { basic_click_events } from "../../RoomphGTMTracking";
function Footer({ page_type }) {
  return (
    <footer
      className="position-relative site-footer py-3"
      id="contact"
      style={{ backgroundColor: "#575757", color: "white" }}
    >
      <div className="container">
        <div className="footer-inner">
          <div className="py-lg-4 footer-most-inner d-flex justify-content-between position-relative">
            <div>
              <div className="text-center social-icons d-flex flex-column justify-content-center align-items-center">
                <img
                  className="im mb-3"
                  // src={process.env.PUBLIC_URL + "/images/logo2.svg"}
                  src={white_logo_url}
                  alt="logo"
                />
                <ul className="d-flex mt-2 mx-0 px-0">
                  <li className="d-inline social-li ">
                    <Link
                      to={{ pathname: agentDetails["facebook_url"] }}
                      target="_blank"
                      className="links box-shadow-1"
                    >
                      <FaFacebookF className="icons" />
                    </Link>
                  </li>
                  <li className="d-inline social-li">
                    <Link
                      to={{ pathname: agentDetails["twitter_url"] }}
                      target="_blank"
                      className="links box-shadow-1"
                    >
                      <FaTwitter className="icons" />
                    </Link>
                  </li>
                  <li className="d-inline social-li">
                    <Link
                      to={{
                        pathname: agentDetails["linkedin_url"],
                      }}
                      target="_blank"
                      className="links box-shadow-1"
                    >
                      <FaLinkedinIn className="icons" />
                    </Link>
                  </li>
                  <li className="d-inline social-li">
                    <Link
                      to={{ pathname: agentDetails["instagram_url"] }}
                      target="_blank"
                      className="links box-shadow-1"
                    >
                      <FaInstagram className="icons" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex flex-column flex-wrap as">
              <div className="f-20 mb-1">
                <u>Useful Links</u>
              </div>
              <a
                target="_blank"
                href={about_us}
                className="mt-1"
                onClick={() => basic_click_events("click_aboutus", page_type)}
              >
                About Us
              </a>
              {agent_bw_userId !== 10008 ? (
                <a
                  className="mt-1"
                  target="_blank"
                  href={termsAndConditions}
                  onClick={() =>
                    basic_click_events("click_termscondition", page_type)
                  }
                >
                  Terms and Conditions
                </a>
              ) : null}

              {/* <a className="mt-1" href='#'>Loyalty</a> */}
              {/* <a className="mt-1" href="/blogs">
                Blog
              </a> */}
              {/* <a className="mt-1" href='#'>Careers</a> */}
              {/* <a className="mt-1" onClick={() => checkAmbassadoe()}>
                Ambassador Program
              </a> */}
              {/* <a href="/add-Property" className="mt-2">
                <button className="btn-white">Add Property</button>
              </a> */}
            </div>
            <div className="footer-right mt-lg-0 mt-md-0 mt-3">
              <div className="mb-4">
                <div className="f-20 mb-1">
                  <u>How can we help?</u>
                </div>
                <a href={`tel:${agentDetails.contact}`}>
                  <span className="f-25">{agentDetails.contact}</span>
                </a>
                <div className="f13">Contact Our Customer Support.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-r">
          {new Date().getFullYear()} - All rights reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
