import { useState } from "react";
import React from "react";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import { viewport } from "@popperjs/core";
import { Slider } from "antd";

export const Head = () => {
  return (
    <div className="Head catainer box-shadow-3 align-items-center justify-content-center flex-column   pt-2  mb-0">
      <div className="w-100 mb-2 ml-2 ">
        <p className="mb-0 third-heading text-orange">Got a minute to spare?</p>
        <h4 className=" sub-heading">Contribute to help fellow travelers</h4>
      </div>
    </div>
  );
};

export const Reviews = () => {
  const [set, setState] = useState(0);

  const [inputValue, setInputValue] = useState(0);

  const valuechange = (newval) => {
    setInputValue(newval);
  };

  return (
    <>
      <Head />
      {set == 0 ? (
        <>
          <div className="container cardstyle mt-3 box-shadow-2  d-flex flex-column align-items-center  mx-auto   ">
            <div className={"w-100 pt-0 px-3 mt-0 "}>
              <progress className="w-100 " max={100} value="10">
                30%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/wifi.png"
                className="Reviewimage"
                alt="Wifi"
              />
            </div>

            <h4 className="mt-2 sub-heading">WiFi </h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider1"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
          <div className="container cardstyle blurred-card  box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div>
              <img
                src="images/Review-image/ac.png"
                className="Reviewimage"
                alt="AC"
              />
            </div>

            <h4 className="mt-2 sub-heading">AC</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider2"
                tooltip={{ open: false }}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 1 ? (
        <>
          <div className="container cardstyle  box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div className={"w-100 pt-0 px-3 mt-0  "}>
              <progress className="w-100 " max={100} value="30">
                30%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/ac.png"
                className="Reviewimage"
                alt="AC"
              />
            </div>

            <h4 className="mt-2 sub-heading">AC</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider3"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
          <div className="container cardstyle blurred-card box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div>
              <img
                src="images/Review-image/cleanliness.png"
                className="Reviewimage"
                alt="Cleanliness"
              />
            </div>

            <h4 className="mt-2 sub-heading">Cleanliness</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider4"
                tooltip={{ open: false }}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 2 ? (
        <>
          <div className="container  cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div className={"w-100 pt-0 px-3 mt-0 "}>
              <progress className="w-100 " max={100} value="50">
                50%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/cleanliness.png"
                className="Reviewimage"
                alt="Cleanliness"
              />
            </div>

            <h4 className="mt-2 sub-heading">Cleanliness</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider5"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
          <div className="container blurred-card  cardstyle box-shadow-2  d-flex flex-column align-items-center mx-auto  mt-3 ">
            <div>
              <img
                src="images/Review-image/location.png"
                className="Reviewimage"
                alt="Location"
              />
            </div>

            <h4 className="mt-2 sub-heading">Location</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider6"
                tooltip={{ open: false }}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 3 ? (
        <>
          <div className="container  cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div className={"w-100 pt-0 px-3 mt-0 "}>
              <progress className="w-100 " max={100} value="70">
                70%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/location.png"
                className="Reviewimage"
                alt="Location"
              />
            </div>

            <h4 className="mt-2 sub-heading">Location</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider7"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
          <div className="container blurred-card cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div>
              <img
                src="images/Review-image/staff.png"
                className="Reviewimage"
                alt="Staff"
              />
            </div>

            <h4 className="mt-2 sub-heading">Staff</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider8"
                tooltip={{ open: false }}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 4 ? (
        <>
          <div className="container  cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div className={"w-100 pt-0 px-3 mt-0 "}>
              <progress className="w-100 " max={100} value="90">
                80%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/staff.png"
                className="Reviewimage"
                alt="Staff"
              />
            </div>

            <h4 className="mt-2 sub-heading">Staff</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider9"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
          <div className="container blurred-card  cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div>
              <img
                src="images/Review-image/staff.png"
                className="Reviewimage"
                alt="Value For Money"
              />
            </div>

            <h4 className="mt-2 sub-heading">Value For Money</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider10"
                tooltip={{ open: false }}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 5 ? (
        <>
          <div className="container cardstyle box-shadow-2  d-flex flex-column align-items-center  mx-auto  mt-3 ">
            <div className={"w-100 pt-0 px-3 mt-0 "}>
              <progress className="w-100 " max={100} value="100">
                100%
              </progress>
            </div>
            <div>
              <img
                src="images/Review-image/staff.png"
                className="Reviewimage"
                alt="Value For Money"
              />
            </div>

            <h4 className="mt-2 sub-heading">Value For Money</h4>

            <div className="sliderstyle w-50">
              <Slider
                min={1}
                max={5}
                name="slider11"
                tooltip={{ open: false }}
                onChange={valuechange}
                trackStyle={{
                  background: "#FF3D00",
                }}
                handleStyle={{
                  borderColor: "#FF3D00",
                }}
              />
              <div className="w-100 d-flex justify-content-between">
                <h1 className="  sliderrang">0</h1>
                <h1 className="  sliderrang">10</h1>
              </div>
            </div>
          </div>
        </>
      ) : set == 6 ? (
        <>
          <div className="container">
            <div className="row d-flex flex-column  justify-content-center ">
              <textarea
                className="box-shadow-2 p-2 mt-4 textarea "
                rows={2}
                placeholder="How was you experience?"
              />
              <textarea
                className="box-shadow-2 mt-0 mt-3 p-2 textarea  "
                rows={2}
                placeholder="What did you like about your stay?"
              />
            </div>
          </div>
        </>
      ) : set == 7 ? (
        <>
          <div className="container">
            <div className="d-flex flex-column pt-5 mt-2 ">
              <div className="w-100 d-flex mb-3 ">
                <h4 className="thanksText catainer ">
                  <b>Thank you for your valuable feedback.</b>
                </h4>
              </div>
              <a href="/">
                <p className="thanks">Go to Home page</p>
              </a>
            </div>
          </div>
        </>
      ) : null}
      <div className="container">
        <div className="row ">
          <div className="mt-4 mb-5 col-sm-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-end">
            {set < 6 ? (
              <>
                {set !== 0 ? (
                  <button
                    type="button"
                    className="btn-topbar-primary-outline mx-3"
                    onClick={() => setState(set > 0 ? set - 1 : 0)}
                  >
                    Previous
                  </button>
                ) : null}
                <button
                  type="submit"
                  className="btn-topbar-primary "
                  onClick={() => setState(set + 1)}
                >
                  Next
                </button>
              </>
            ) : set == 6 ? (
              <button
                type="submit"
                onClick={() => setState(set + 1)}
                className="btn-topbar-primary "
              >
                Submit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export const Review = () => {
  const [user, setUser] = useState("");

  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <Reviews />
      <Footer page_type="review"/>
    </>
  );
};
