import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import GoogleMapContainer from './GoogleMapContainer';
import { isMobile } from 'react-device-detect';
import Topbar from '../Topbar/Topbar';
import Footer from '../footer/Footer';

function useFetchData() {
    const params = useParams();

    const cityDetails = [{
        name: 'Karachi', lat: 24.9056, long: 67.0822
    }, {
        name: 'Islamabad', long: 73.0551, lat: 33.69
    }, {
        name: 'Rawalpindi', long: 73.071442, lat: 33.626057
    }, {
        name: 'Lahore', long: 74.329376, lat: 31.582045
    }]
    const [loading, setLoading] = useState([]);
    const [responseData, setData] = useState("");
    const [cityDet, setcityDet] = useState([]);

    useEffect(async () => {
        setLoading(true);
        console.log("Executed only once!");
        console.log(params.city)
        setcityDet(cityDetails.find(cd => cd.name.toLowerCase() == params.city.toLowerCase()));
        const response = await fetch('/api/world', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postCity: params.city, postCheckIn: params.checkin, postCheckOut: params.checkout, postAdults: params.adults, postRooms: params.rooms, postPriceStart: '', postPriceEnd: '', postRating: '', postPremium: '', postCategory: '' })
        })
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        var results = body.Success.result;
        results=results.filter(r=>r.MinRate[0] !== '');
        setData(results);
        setLoading(false);

    }, []);
    console.log(responseData);
    console.log(cityDet);
    return { loading, responseData, cityDet };
}
function Maps() {
    const [user, setUser] = useState('');
    const { loading, responseData, cityDet } = useFetchData();
    if (loading) {
        return (
            <>
                <div className='loading-div'>
                    <div className='inner-div'>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        <div class="gr-medium">Loading map..</div></div>
                </div>
            </>

        )
    } else {
        return (
            <section className="position-relative">
                <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
                {isMobile ?
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-12 px-0 left-0 right-0 position-absolute bottom-0 z-index-999">
                                <div className="bg-white scrollbar" id="scroll-4">
                                    <div className="feature-hotel-sidebar w-100">
                                        <ReturnBody data={responseData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :

                    <section className='position-relative'>
                        <div className="container-fluid px-5">
                            <div className="row">
                                <div className='col-3 left-0 mt-5 position-absolute top-0 z-index'>
                                    <div className='map-main-container bg-white w-300 float-left border-radius-10'>
                                        {/* <ul class="nav nav-tabs text-center p-2">
                                            <li class="nav-item col border-right px-0">
                                                <a class="nav-link active border-0 p-0 f-15" data-toggle="tab" href="#mapHotelsList">Hotels List</a>
                                            </li>
                                            <li class="nav-item col px-0">
                                                <a class="nav-link border-0 p-0 f-15 text-black" data-toggle="tab" href="#mapFilters">Filters</a>
                                            </li>
                                        </ul> */}
                                        <div className='tab-content scrollbar' id="scroll-4">
                                            <div className='tab-pane container active' id="mapHotelsList">
                                                <div className='feature-hotel-sidebar mb-4 w-100'>
                                                    {responseData.map((hotel, index) => {
                                                            return (

                                                                <Link key={index} to={`/hotels&city=${hotel.CityName}&check_in=${(new Date()).getFullYear()}-${(new Date()).getMonth() + 1}-${(new Date()).getDate()}&check_out=${(new Date()).getFullYear()}-${(new Date()).getMonth() + 1}-${(new Date()).getDate() + 2}&adult=2&rooms=1&nights=3&hotel_id=${hotel.AccommodationId[0]}`} target="_new" id="map0" style={{ "outline": 0 }}>
                                                                    <div class="feature-hotel-box-desk mb-3 float-left w-100 datas" data-id="0">

                                                                        <div class="feature-hotel-img-desk overflow-hidden position-relative">
                                                                            <img src={hotel.ImageURL[0]} alt="" class="img-fluid h-60 w-100" />
                                                                        </div>
                                                                        <div class="feature-hotel-description-desk float-left w-100 mt-2">
                                                                            <div class="feature-hotel-title-desk float-left">
                                                                                <p class="text-black text-capitalize mb-0 f-15 fouth-heading">
                                                                                    {hotel.AccommodationName[0]}
                                                                                </p>
                                                                                <p class="roboto-light ft-12 mb-0 overflow-texts pr-5">
                                                                                    {hotel.Address[0]}
                                                                                </p>
                                                                            </div>
                                                                            <div class="feature-hotel-star-rating-desk float-left">
                                                                                <p class="mb-0 text-orange ft-16 fouth-heading">{hotel.MinRate[0] ? Number(hotel.MinRate[0]).toLocaleString() : "Fully Booked"} Rs</p>
                                                                                <p class="mb-0 roboto-light ft-12">avg. per night</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Link>
                                                            )

                                                    })}

                                                </div>
                                            </div>


                                            <div className='tab-pane container fade' id='mapFilters'>
                                                <h3>Cooming Soon</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <div id={isMobile ? "map_canvas" : "desktop-map"} className="w-100">
                    <GoogleMapContainer className="w-100" data={responseData} cityDetail={cityDet} />
                </div>
                <Footer page_type="maps"/>
            </section>
        )
    }



}

function ReturnBody(props) {
    // console.log(props.data)

    if (props.data.length > 0) {
        return props.data.map((res, index) => {
            var Price = res.MinRate[0];
            return (

                <a href="#" target="_parent" id={index}>
                    <div className="feature-hotel-box mb-2 float-left w-100 datas px-3 pt-2" data-id={index}>
                        <div className="feature-hotel-img float-left mt-2">
                            <img src={res.ImageURL[0]} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-hotel-description float-left">
                            <div className="feature-hotel-title float-left">
                                <p className="text-black text-capitalize mb-0 gr-medium ft-12">
                                    {res.AccommodationName[0]}
                                </p>
                                <p className="roboto-light ft-10 mb-0">
                                    {res.Address[0]}
                                </p>
                            </div>
                            <div className="feature-hotel-star-rating float-left">
                                <p className="mb-0 text-orange ft-12">Rs. {Price ? Number(Price).toLocaleString() : "Fully Booked"}</p>
                                <p className="mb-0 roboto-light ft-10">avg. per night</p>
                            </div>
                        </div>
                    </div>
                </a>);
        })
    } else {
        return (
            <div>
                <h1>Not found</h1>
            </div>)
    }


}

export default Maps