import React, { useEffect, useState } from "react";
import "./PaymentGateway.css";
import { RxCross1 } from "react-icons/rx";
import {
  PopUpBanner,
  generateRandomNDigits,
  logo_url,
  percentageCalculator,
  sendOTP,
  termsAndConditions,
} from "../BasicComponents/BasicFunctionScript";
import { privateKey, publicKey } from "./KeysRsa";
import { JSEncrypt } from "jsencrypt";
import { Link } from "react-router-dom";

const PaymentGateway = ({
  BookingData,
  hotelID,
  hotelParams,
  hotelDeatails,
  Total,
  contactInfo,
  roomqty,
  finalPrice,
  Tax,
  TaxAmount,
  discount,
  ratePlanId,
}) => {
  //console.log(hotelDeatails, hotelParams);
  const discountPrice = discount
    ? percentageCalculator(Total, discount.discount_percentage)
    : 0;
  const startTimingMinutes = 10;
  let time = startTimingMinutes * 60;
  const [timer, settimer] = useState("10:00");
  const [popup, setPop] = useState(true);
  const [steps, setSteps] = useState(1);
  const [Eroor, setEroor] = useState("");
  const [loading, setloading] = useState(false);

  const [disabled, setdisabled] = useState(false);
  var accountHbl = localStorage.getItem("accountHbl");

  accountHbl = accountHbl ? JSON.parse(accountHbl) : "";

  useEffect(() => {
    if (steps == 2) {
      setInterval(() => {
        countDownTime();
      }, 1000);
    }
  }, [steps]);

  const countDownTime = () => {
    if (time >= 0) {
      let minutes = Math.floor(time / 60);
      let seconds = time % 60;
      time--;
      // console.log(time);
      settimer(
        `${String(minutes).padStart(2, 0)}:${String(seconds).padStart(2, 0)}`
      );
    } else {
      setdisabled(true);
    }
  };

  const [customerPay, setcustomerPay] = useState({
    cnic: accountHbl ? accountHbl.cnic : "",
    accountNo: accountHbl ? accountHbl.account_no : "",
    Channel: accountHbl ? accountHbl.channel : "Ascendant_Website",
    otp: "",
    amount: (Total - discountPrice + TaxAmount).toString(),
    RefId: "",
    sid: accountHbl ? accountHbl.sid : "",
    ClientGuid: `CG-${BookingData.BookingID}`,
  });

  const handleChange = (event) => {
    event.preventDefault();
    setcustomerPay({ ...customerPay, [event.target.name]: event.target.value });
  };

  const closePopup = () => {
    setPop(false);
  };

  const submitfn = async () => {
    if (steps == 1 || steps == 2) {
      setloading(true);
      var encrypt = new JSEncrypt();
      encrypt.setPrivateKey(privateKey);
      if (steps == 1) {
        var data = {
          cnic: customerPay.cnic,
          accountNo: customerPay.accountNo,
          Channel: customerPay.Channel,
          otp: "",
          amount: customerPay.amount,
          Stan: `${generateRandomNDigits()}-${customerPay.accountNo}-${
            customerPay.cnic
          }`,
          ClientGuid: customerPay.ClientGuid,
          Narration: customerPay.Narration,
        };
      } else if (steps == 2) {
        var data = {
          cnic: customerPay.cnic,
          accountNo: customerPay.accountNo,
          Channel: customerPay.Channel,
          otp: customerPay.otp,
          amount: customerPay.amount,
          Stan: `${generateRandomNDigits()}-${customerPay.accountNo}-${
            customerPay.cnic
          }`,
          ClientGuid: customerPay.ClientGuid,
          Narration: customerPay.Narration,
          RefId: customerPay.RefId,
          sid: customerPay.sid,
          AccommodationName: hotelDeatails.AccommodationName[0],
          ChecnInDate: hotelParams.checkin,
          CheckoutDate: hotelParams.checkout,
        };
      }
      const sendOTP_responce = await sendOTP(data);
      console.log("HBL=>", sendOTP_responce);
      if (sendOTP_responce.status == "success") {
        if (steps == 1) {
          setcustomerPay({ ...customerPay, RefId: sendOTP_responce.RefId });
        }
        setSteps(steps + 1);
        if (steps == 2) {
          //to confirm booking
          fetch("/api/update_book", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              accommodationid: hotelID,
              bookingid: BookingData.BookingID,
              pincode: BookingData.PinCode,
              status: 1,
            }),
          })
            .then((data) => data.json())
            .then((canceldata) => {
              var saveData = {
                Bookingid: BookingData.BookingID[0],
                pinCode: BookingData.PinCode[0],
                TranCode: "03",
                Stan: data.Stan,
                Channel: data.Channel,
                UserID: "ascendantadmin",
                Password: "Karachi@@1",
                ClientGuid: data.ClientGuid,
                SrcAccount: customerPay.accountNo,
                SrcCnic: customerPay.cnic,
                Amount1: data.amount,
                RefId: data.RefId,
                Narration: customerPay.Narration,
              };
              console.log(saveData);
              fetch("/api/save_payment", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(saveData),
              }).then((r) => {
                console.log(r);
              });
              //console.log(canceldata);
            });
        }
      } else {
        setEroor(sendOTP_responce.ResponseMessage);
        fetch("/api/update_book", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accommodationid: hotelID,
            bookingid: BookingData.BookingID,
            pincode: BookingData.PinCode,
            status: 9,
          }),
        })
          .then((data) => data.json())
          .then((canceldata) => {
            console.log(canceldata);
          });
      }
      setloading(false);
    }
  };
  return popup ? (
    <div>
      <div className="main">
        <div className="hbl_popup position_relative">
          <div className="hbl_popup-header">
            <div className="font_family_medium">
              Pay via HBL Direct Transfer
            </div>
            <RxCross1 onClick={closePopup} className="cursor" />
          </div>
          <div className="hbl_popup-body">
            {Eroor ? (
              <div
                className="alert alert-danger alert-dismissible fade show f-15"
                role="alert"
              >
                <strong>ERROR!</strong> {Eroor}.
                <button
                  type="button"
                  class="btn-close"
                  onClick={() => setEroor("")}
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            ) : null}

            <div className="payment_grid p_m_2">
              <img className="payment_logo" src={logo_url} alt="logo" />
              <div className="paymet_grid_col p_grid_col_2 red font_family_medium">
                {steps == 2 ? timer : ""}
              </div>
            </div>
            <div className="payment_summary">
              <div className="font_family_bold font_8">Payment Amount</div>
              <div className="payment_inner_summary mt-2">
                <div className="payment_grid">
                  <div className="paymet_grid_col p_grid_col_1">
                    {roomqty.length} Room
                  </div>
                  <div className="paymet_grid_col p_grid_col_2 font_family_bold">
                    PKR {Total.toLocaleString()}
                  </div>
                </div>

                <div className="payment_grid">
                  {Tax.map((tax, index) => {
                    return (
                      <>
                        <div className="paymet_grid_col p_grid_col_1">
                          {tax.$.TaxName} (
                          {Number(tax.$.TaxPercent).toLocaleString()}%)
                        </div>
                        <div className="paymet_grid_col p_grid_col_2 font_family_bold">
                          {Number(
                            (tax.$.TaxPercent * Total) / 100
                          ).toLocaleString()}
                        </div>
                      </>

                      // <p className="row f-15" key={index}>
                      //   <span className="col-lg-4 col-6 fouth-heading">
                      //     Tax ({Number(tax.$.TaxPercent).toLocaleString()}%{" "}
                      //     {tax.$.TaxName}):
                      //   </span>
                      //   <span className="col-lg-5 col-6 text-right">
                      //     {hotelDeatails["CurrencyCode"][0] == "USD"
                      //       ? "PKR"
                      //       : hotelDeatails["CurrencyCode"][0]}{" "}
                      //     {Number(
                      //       (tax.$.TaxPercent * Total) / 100
                      //     ).toLocaleString()}
                      //   </span>
                      // </p>
                    );
                  })}
                  {/* <div className="paymet_grid_col p_grid_col_1">
                    G.S.T (16%) excluded
                  </div>
                  <div className="paymet_grid_col p_grid_col_2 font_family_bold">
                    PKR 1400
                  </div> */}
                </div>
                <div className="payment_grid p_m_3 color_primary">
                  <div className="paymet_grid_col p_grid_col_1 color_primary font_family_bold">
                    Price ({hotelParams.adults} Guest
                    {hotelParams.adults > 1 ? "s" : ""})
                  </div>
                  <div className="paymet_grid_col p_grid_col_2 color_primary font_family_bold">
                    PKR {(Total - discountPrice + TaxAmount).toLocaleString()}
                  </div>
                </div>
              </div>
              {steps == 1 ? (
                <div className="step_1">
                  <div className="mt-2">
                    <div className="payment_labels color_primary font_8 font_family_bold">
                      Customer CNIC
                    </div>
                    <input
                      class="payment_inputs"
                      id="text-input"
                      placeholder="Format: 1234567891234"
                      type="number"
                      autoComplete="off"
                      value={customerPay.cnic}
                      name="cnic"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="payment_labels color_primary font_8 font_family_bold">
                      HBL Account #
                    </div>
                    <input
                      class="payment_inputs"
                      placeholder="Format: BBBBAAAAAAAATT"
                      id="text-input"
                      type="number"
                      autoComplete="off"
                      value={customerPay.accountNo}
                      name="accountNo"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mt-3">
                    <a
                      className="p_terms"
                      href={termsAndConditions}
                      target="_blank"
                    >
                      Click here to view Terms and Conditions
                    </a>
                  </div>
                </div>
              ) : steps == 2 ? (
                <div className="step_2 mt-3">
                  <div className="font_family_medium font_8 color_primary">
                    Enter  OTP to Authorize your Transaction.
                  </div>
                  <div className="note font_6 font_family_bold mb-1">Note:</div>
                  <div className="note_des font_6">
                    If this is your first HBL Direct Transfer transaction, you
                    will receive a verification call.
                  </div>
                  <div className="note_des font_6">
                    After successful verification an OTP will be sent via SMS.
                  </div>
                  <div className="font_family_bold text-center font_6 my-2">
                    "Please do not share the OTP with anyone"
                  </div>
                  <div>
                    <input
                      class="payment_inputs otp_input"
                      placeholder="Format: 12345"
                      id="text-input"
                      type="text"
                      autoComplete="off"
                      value={customerPay.otp}
                      name="otp"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 font_6 text-right">
                    Didn't receive code yet?{" "}
                    <span className="resendSms">Resend SMS</span>
                  </div>
                </div>
              ) : steps == 3 ? (
                <div className="my-3">
                  <div className="font_family_bold text-center font_8 my-2 color_primary">
                    Your Transaction is Successfull!
                  </div>
                  <div className="text-center font_8">
                    <Link className="resendSms" to="/">
                      Go to Home Page.
                    </Link>
                  </div>
                </div>
              ) : null}
              {/* <div className="step_1">
                <div className="mt-3">
                  <div className="payment_labels color_primary font_8">
                    Customer CNIC
                  </div>
                  <input
                    class="payment_inputs"
                    id="text-input"
                    placeholder="Format: 1234567891234"
                    type="text"
                    autoComplete="off"
                    value={customerPay.cnic}
                    name="cnic"
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-3">
                  <div className="payment_labels color_primary font_8">
                    HBL Account #
                  </div>
                  <input
                    class="payment_inputs"
                    placeholder="Format: BBBBAAAAAAAATT"
                    id="text-input"
                    type="text"
                    autoComplete="off"
                    value={customerPay.accountNo}
                    name="accountNo"
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-3">
                  <a className="p_terms">
                    Click here to view Terms and Conditions
                  </a>
                </div>
              </div> */}
              {steps == 2 || steps == 1 ? (
                <button
                  disabled={disabled}
                  className="payment_proceed"
                  onClick={() => submitfn()}
                >
                  {loading ? (
                    <div class="spinner-grow" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : null}{" "}
                  Proceed
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PaymentGateway;
