import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { DealsArray } from "../BasicComponents/BasicFunctionScript";
import { CiSearch } from "react-icons/ci";
import { Dialog } from "@mui/material";
import DealsSearchBox from "./DealsSearchBox";
import { ReactComponent as Stars } from "../../Assests/Asset100.svg";
import { click_deals_property, page_view } from "../../RoomphGTMTracking";

function DealsProperty() {
  useEffect(() => {
    page_view("Deals_property");
  }, []);
  const [propertyDeals, setPropertyDeals] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [dealsDetail, setdealsDetail] = useState({
    city: "",
    hotelID: "",
    dealsData: "",
    cityArray: "",
  });
  const { id } = useParams();


  useEffect(() => {
    DealsArray(id).then((dataR) => {
      //console.log(dataR);
      fetch("/api/accommodation_promotions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pid: id }),
      })
        .then((check) => check.json())
        .then((d) => {
          
          //console.log(d);
          fetch("/api/cities_list")
            .then((r) => r.json())
            .then((c) => {
              //console.log(c)

              setdealsDetail({
                ...dealsDetail,
                cityArray: c.express.Collection.Rows,
                dealsData: dataR,
              });
              if (d.Collection) {
                setPropertyDeals(
                  d.Collection.Rows.sort((a, b) => {
                    return (
                      Number(b.discount_percentage) -
                      Number(a.discount_percentage)
                    );
                  })
                );
              }
            });
        });
    });
  }, []);

  function Cards() {
    return (
      <div className="col-lg-4 col-md-4 col-6 mr-20 mb-5">
        <div className="dealproperty-container">
          <Skeleton width="100%" height="100%" />
        </div>
        <div className="main-heading mt-3 mb-0 text-dark">
          <Skeleton width="100%" height="100%" />
        </div>
        <div className="mb-1">
          <Skeleton width="30%" height="100%" />
        </div>
        <div className="text-orange f-22">
          <Skeleton width="60%" height="100%" />
        </div>
      </div>
    );
  }
  function LoadingProperty() {
    return (
      <>
        <Cards />
        <Cards />
        <Cards />
        <Cards />
        <Cards />
        <Cards />
      </>
    );
  }

  const handleClose = () => {
    setOpen(!open);
  };
  const checkAvailibilty = (hotelId, cityId) => {
    setdealsDetail({
      ...dealsDetail,
      hotelID: hotelId,
      city: dealsDetail.cityArray.find((c) => c.CityId == Number(cityId))
        .CityName,
    });
    setOpen(true);
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            borderRadius: "35px",
            padding: "1.5rem",
            width: "fit-content",
            overflow: "visible",
            maxWidth: "max-content",
            boxShadow: "1px 1px 0px 1px rgb(0 0 0 / 40%)",
          },
        }}
      >
        <DealsSearchBox
          dealsDetail={dealsDetail}
          setdealsDetail={setdealsDetail}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      </Dialog>
      <Topbar color="#FF3D00" page="deals" user={user} setUser={setUser} />
      <section className="deals-cc container">
        <div className="deals-container p-3">
          <div className="search-container-deals d-flex justify-content-between align-items-center">
            <div className="checkoutDetails">
              {propertyDeals ? (
                <div className="bold">
                  Offer valid&nbsp;
                  {moment().isBetween(
                    moment(dealsDetail.dealsData.valid_from, "YYYY-MM-DD"),
                    moment(dealsDetail.dealsData.valid_till, "YYYY-MM-DD"),
                    "days",
                    "[]"
                  )
                    ? "through today"
                    : "from " +
                      moment(
                        dealsDetail.dealsData.valid_from,
                        "YYYY-MM-DD"
                      ).format("Do MMMM, YYYY")}
                  &nbsp;to&nbsp;
                  {moment(
                    dealsDetail.dealsData.valid_till,
                    "YYYY-MM-DD"
                  ).format("Do MMMM, YYYY")}
                  .
                </div>
              ) : (
                <Skeleton />
              )}

              {/* <div>
                {moment(dealsDetail.dealsData.valid_from).format("MMM D, YYYY")}
                ,{" "}
                {moment(dealsDetail.dealsData.valid_till).format("MMM D, YYYY")}
              </div> */}
            </div>
            <div>
              <CiSearch className="f-25" />
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5 mb-5">
        <div className="main-heading text-center promotion-type-text">
          {dealsDetail.dealsData.promotion_type}
        </div>
        <section>
          {/* <LoadingProperty /> */}
          <div className="row mt-5 mx-1">
            {propertyDeals ? (
              propertyDeals.map((d, i) => {
                return (
                  <div
                    className="col-lg-4 col-md-4 col-6 mx-20 mb-5"
                    key={i}
                    onClick={() => {
                      checkAvailibilty(
                        d.accommodation_BE_id,
                        d.promotion_city_id
                      );
                      click_deals_property(
                        d.id,
                        d.discount_percentage,
                        dealsDetail.dealsData.valid_till,
                        dealsDetail.dealsData.valid_from,
                        d.accommodation_BE_id,
                        d.accommodation_name
                      );
                    }}
                  >
                    <div
                      className="dealproperty-container position-relative"
                      style={{
                        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${d.accommodation_image})`,
                      }}
                    >
                      {d.discount_percentage ? (
                        <div className="red-banneer d-flex justify-content-center align-items-center flex-column">
                          <div>
                            <div className="red-banner-main">
                              {d.discount_percentage}% Off
                            </div>
                          </div>
                          {dealsDetail.dealsData.valid_till ? (
                            <div className="text-center text-light italic f-12">
                              Expires in{" "}
                              {moment(
                                dealsDetail.dealsData.valid_till,
                                "YYYY-MM-DD"
                              ).format("MMMM")}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    <div className="main-heading mt-3 mb-0 text-dark">
                      {d.accommodation_name}
                    </div>
                    <div className="mb-2">
                      {Array.apply(null, {
                        length: d.accommodation_reviewStar
                          ? Number(d.accommodation_reviewStar)
                          : 0,
                      })
                        .map(Number.call, Number)
                        .map((item) => {
                          return <Stars className="imgWidr" key={item} />;
                        })}
                    </div>
                    <div className="text-orange f-22 line-height7">
                      <s className="strike">
                        <span className="text-lighter">Rs. </span>
                        <span className="bold">
                          {Number(d.regular_price).toLocaleString()}
                        </span>
                      </s>
                      &nbsp;&nbsp;<span className="text-lighter">Rs. </span>
                      <span className="bold">
                        {Number(d.discounted_price).toLocaleString()}
                      </span>
                    </div>
                    {/* </Link> */}
                  </div>
                );
              })
            ) : (
              <LoadingProperty />
            )}
          </div>
        </section>
      </section>
      <Footer page_type="deals"/>
    </>
  );
}

export default DealsProperty;
