export const  publicKey=`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtwAh5lLx6B4I39V5dCXD
B8s9gIWNdL1HasTd3K/L1lsU0yf81/CChyWvdm9MjS/PwhCeBQZj4v0wWFsAg9+F
pwM7IlkrOcaLjAp7Gjb1eOmiTRkQPwYX3SC9hCO1kU/os7TZDdGm3qUcVkRhdpsP
RU6knC/AYQ/WUZ+r2OhvRglfoDorj5nkymmGTDN9fiaKV7uUuuFv9coKdDK3ZBLo
ol9y6eHEHBY71xcG3DX16N4Xbj4thuVWQCa6Cer7OW7v9qzynWQrBI8HV3KnabMW
3M1UJAMBDKlmaUwzT4MDYiTBf0P+Par+ARMeH93XmVLgk6vOGqufgHius6Qnn3JT
kQIDAQAB
-----END PUBLIC KEY-----`;


export const privateKey=`-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAghHEpkjUOD69n30iKuufWpH4yw9UlERpmd+1SqmdeOUyw9jC
416lG43dRCHNEApXZmNJamjyitCWr5Pzn+rJlHPUnQ7qGs3RpvdgD94uw+YOnsPT
PnIvyOKjNgLLB26gxvx2i45DNuUwSexNZJZjd0AIdCkENEUywikXGYQn04ZG+A9S
kV5kQF9CuJQ1ovy2yHGeMbCODaVU3mL/DfE68BR/b+gm7yiqVoIRth6E6lR65IW0
vbtZVp055VsNvIOFBQctLhSmzd6Y2LFHSaZcx9gU87o1+7YEKy/AotNfF4V5HUca
4opzqVyCKes9KwUgSmLKc97oVqm+4B1BQTcFWQIDAQABAoIBAAy5Rj5qA3CZGp2e
dRgzwnvuqaLblg+G/bmSu75ZB1GLQkMMUkxBtu8SGqIOmGvIRzJ55fWl3IwdyY/V
blCnfB6skhDnI5mJpsepjVkgB/0b5TQ8vc5vByOkoAipj0jj1pCAo+wU95hIJUgl
+JAtcf95YRpT5NhkKIn70Qmq9uZnKWboDlA0VOadwWVN3LaAEYc1LUtrhkEJf8Sr
SK3OErt8zTiXb3yMpjiBp6wuA+VdaiXT4zPzAwgX2FD6Bs8fr/DVZqdaXq5MGxky
kbNZgg/7MI8q66Kn04Vt/zKh2tUCMaC2RqcZN9RYrjZRvoiIlEQYCmDy97GzGpuG
BrrXV/ECgYEAyoWhl3/0jnqw9HF/AGIhRqTJU96ez9fhPvqvHSkoO1DjyVIdDNMI
ANOmSu+M86WYS7/dX7UJEk6CuruVNes2+xmedj5qEAlH5wfyW4r6Vj7fVGPU+O1z
cqJaeBXRUR/PZdnxTW96hi4h9j7rPOOPeiLRd9sy7vqmeKFnC2Bvrr0CgYEApGpi
V23J8hXqrnhO1AvB10VfCUCg1IJdOuYtI9tU0RRT3NWIA6ENvm3R049vlTTetE38
L9MPW7Y/xrow3h7n3oNqA0t3twu3BfBOCfnhwba5FHvKc9GLwXEiFhely2hREDtk
hcwxtmS6Gt9NRjAYYJtvrnqW8cknPYPOwhUV+M0CgYEAyi0HeWfKq6Y+G3lmUOql
z5FYTzryDgm0mpvFz3Wz7Ss+i09HIvNVjQW9gBeO/fj1hcF/bTkcWkM4bNQxDAfJ
9njH4cFDPskVF8WuI8clTI4tg2C6Tts1ol4DtNgLA1KfSE1/gE9m4KcTTnHXgwyl
wkcaF+4i9cISCGEQrqHSPH0CgYAHe8wkHJwvh63Z6SH1Zt+Y09CClE19vr1NnAJ7
+lS++4ThrVp2h9MfUtJVJdc3XGOyo0YSSzIMw5vdiMYGpjN5nlOp+LpnO4JGI+z5
aUVMUuvTsdrCMMyCroqVpNfoDUR7T6jAqk2s9qK97GIRCslhRFHuCChssvI1S5WS
ofERFQKBgBGv2n2H1AkjToTWSwKYhFeontCS+q1LZhdarSicSAfEWxzAcHlc39ZB
/ezTZvwZ/MkW1XUrMThU3PTPOEyiOZTtvenb4Xw1331jDJ1dGXrHbyahMTd1kBPq
mFHLWwpI2ex7lspArKwlh2dJ3DpeaFgJqXVgkmjTbF5kWuMXDnUU
-----END RSA PRIVATE KEY-----`